import React from 'react';
import '@vkontakte/vkui/dist/vkui.css';
import Home from './panels/Home';

class App extends React.Component {
	state = {
		loading: true
	};

	componentDidMount() {
    	demoAsyncCall().then(() => this.setState({ loading: false }));
	}

	render() {
		const { loading } = this.state;

		if(loading) {
			return null; // render null when app is not ready
		}

		return (
			<Home />
		);
	}
}

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 1500));
}

export default App;
