import 'core-js/es6/map';
import 'core-js/es6/set';
import React from 'react';
import ReactDOM from 'react-dom';
import connect from '@vkontakte/vkui-connect';
import App from './App';

connect.send('VKWebAppInit', {});
connect.send("VKWebAppSetViewSettings", {"status_bar_style": "light", "action_bar_color": "#891d15"});
ReactDOM.render(<App />, document.getElementById('root'));
