import React from 'react';
import { FixedLayout, Div, Button } from '@vkontakte/vkui';
import './main.css';

const Home = ({ id }) => (

	<div class ="MainScreen">
		<div class="MainScreen_inner">
			<div class="MainScreen_container">
				<div class="MainScreen_container_inner">
					<div class="MainScreen_icon"></div>
					<div class="MainScreen_title">Акция «9 мая в эфире»</div>
					<div class="MainScreen_list">
						<div class="MainScreen_list_item">
							<span class="MainScreen_list_item_number number_one">
								1.
							</span>
							<span class="MainScreen_list_item_text">
								Начните прямую трансляцию ВКонтакте со своего телефона.
							</span>
						</div>
						<div class="MainScreen_list_item">
							<span class="MainScreen_list_item_number number_two">
								2.
							</span>
							<span class="MainScreen_list_item_text">
								Укажите в названии трансляции тематический хэштег <a class="MainScreen_link" href="https://vk.com/feed?section=search&q=%239%D0%BC%D0%B0%D1%8F%D0%B2%D1%8D%D1%84%D0%B8%D1%80%D0%B5" target="_blank">#9маявэфире</a>.
							</span>
						</div>
						<div class="MainScreen_list_item">
							<span class="MainScreen_list_item_number number_three">
								3.
							</span>
							<span class="MainScreen_list_item_text">
								В прямом эфире расскажите о подвигах своих предков или покажите, как отмечают День Победы в Вашем регионе.
							</span>
						</div>
						<div class="MainScreen_list_item last">
							<span class="MainScreen_list_item_number number_four">
								4.
							</span>
							<span class="MainScreen_list_item_text">
								Лучшие видео смогут попасть в каталог <a class="MainScreen_link MainScreen_lives_link_mobile" href="https://vk.com/lives" target="_blank">трансляций ВКонтакте</a><a class="MainScreen_link MainScreen_lives_link_web" href="https://vk.com/video" target="_blank">трансляций ВКонтакте</a>.
							</span>
						</div>
					</div>
					<FixedLayout vertical="bottom">
					  <Div className="MainScreen_footer">


					  	<Button className="MainScreen_button MainScreen_button_mobile" size="xl" component="a" href="https://vk.com/camera?section=live&title=%239%D0%BC%D0%B0%D1%8F%D0%B2%D1%8D%D1%84%D0%B8%D1%80%D0%B5" target="_blank">Начать трансляцию</Button>
					  	<Button className="MainScreen_button MainScreen_button_web" size="xl" component="a" href="https://vk.com/@authors-mobile-lives" target="_blank">Как начать трансляцию?</Button>

					  </Div>
					</FixedLayout>
				</div>
			</div>
		</div>
	</div>
);

export default Home;
